<template>
  <div class="home-page">
    <!-- Welcome section -->
    <!-- Main content section -->
    <MainContent />
  </div>
</template>

<script>
import MainContent from '@/components/MainContent.vue';


export default {
  name: 'HomePage',
  components: {
    MainContent,

  }
};
</script>

<style scoped>
.home-page {
  /* Styles for the homepage container */
}

.welcome-section h1 {
  /* Styles for the welcome message */
}

/* Add additional global styles if needed */
</style>
