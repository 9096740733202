<template>
  <footer class="footer">
    <p>© 2024 IntelligentWar. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterView',
};
</script>

<!-- Add styles if needed -->
<style scoped>
.footer {
  /* Your styles here */
}
</style>
