<template>
  <header class="header">
    <div class="title-and-clock-container">
      <router-link to="/">
        <h1 class="header-title"><strong>I</strong>NTELLIGENT <strong>W</strong>AR</h1>
      </router-link>
      <TimeClock />
    </div>
  </header>
</template>

<script>
import TimeClock from '@/components/TimeClock.vue';

export default {
  name: 'HeaderView',
  components: {
    TimeClock,
  },
};
</script>

<style scoped>
.header {
  display: flex;
  font-family: 'Playfair Display', sans-serif;
  justify-content: left;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 0px 0px rgb(255, 255, 255);
}

a:hover, a:visited, a:active {
  text-decoration: solid;
}

.title-and-clock-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 1200px; /* Adjust as necessary */
  margin: auto; /* Centers the title */
  
}


.header-title {
  font-size: 2vw;
  margin: 0;
  color: #000000;
  max-font-size: 36px; /* Sets a maximum font size */
}

/* Ensure that the body does not push down the header content */
body {
  margin-top: 0;
  padding-top: 0; /* Make sure this applies only to the top padding */
}

/* Additional styles for TimeClock if necessary */
</style>
