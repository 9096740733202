<template>
  <div id="app">
    <HeaderView />
    <router-view /> <!-- This is where Vue Router will render the matched route component -->
    <FooterView />
  </div>
</template>

<script>
// Import your header and footer components only, as they are static
import HeaderView from '@/components/HeaderView.vue';
import FooterView from '@/components/FooterView.vue';
//import NewsTicker from '@/components/NewsTicker.vue';

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView
  },
};
</script>

<style>
/* Your global styles can go here */
</style>
