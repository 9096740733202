<template>
  <div class="article-page">
    <h1>Static Article Title</h1>
    <p>This is a static test article to verify navigation and rendering.</p>
  </div>
</template>

<script>
export default {
  name: 'ArticlePage',
};
</script>

<style scoped>
  .article-page {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }

  h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1em;
    line-height: 1.6;
  }
</style>
