<template>
  <div v-if="article" class="article-page">
    
    <h1>{{ article.title }}</h1>
    <span class="article-author">By J.Adams on</span><time class="article-date">{{ new Date(article.publishedDate).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) }}</time>
        <div class="article-metadata">
  
  </div>

  <div class="image-container">
    <img
    :src="`/img/${article.id}.png`"
      alt="Article Image"
      style="width: 500px; height: 500px;"
    />

    </div>

 <div class="article-content" v-html="article.content"></div>
 <div class="social-share-icons">
  
  <!-- LinkedIn -->
  <a @click.prevent="openPopup(`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`)">
    <img src="@/assets/icons/linkedin.svg" alt="LinkedIn">
  </a>
  <!-- Facebook -->
  <a @click.prevent="openPopup(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`)">
    <img src="@/assets/icons/meta.svg" alt="Facebook">
  </a>
  <!-- Twitter -->
  <a @click.prevent="openPopup(`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`)">
    <img src="@/assets/icons/x.svg" alt="Twitter">
  </a>
  <!-- Email -->
  <a :href="`mailto:?subject=${encodedTitle}&body=${encodedUrl}`">
    <img src="@/assets/icons/email.svg" alt="EMail"> <!-- Make sure you've imported emailIcon as shown above -->
  </a>
</div>
    <div class="article-tags">
            <span v-for="tag in article.tags" :key="tag" class="tag">{{ tag }}&nbsp;</span>
          </div>
          
  </div>
  <div v-else>
    Loading article...
    
  </div>
  
</template>

<script>

export default {
  name: 'ArticlePage',
  data() {
    return {
      article: null, // Initialize article as null
    };
  },
  computed: {
    encodedUrl() {
      return encodeURIComponent(window.location.href);
    },
    encodedTitle() {
      return encodeURIComponent(this.article ? this.article.title : '');
    },
    encodedSummary() {
      return encodeURIComponent(this.article ? this.article.summary : '');
    },
  },
  watch: {
    '$route': 'fetchArticle', // Refetch article when route changes
  },
  mounted() {
    this.fetchArticle();
  },
  methods: {
    fetchArticle() {
      const slug = this.$route.params.slug; // Get the slug from URL
      fetch(`/articles/${slug}.json`)
        .then(response => response.json())
        .then(data => {
          // Replace \n and \r\n with <br> for HTML rendering
          data.content = '<p>' + data.content.replace(/\r?\n/g, '</p><p>') + '</p>';
          this.article = data; // Set the fetched data to the article
        })
        .catch(error => console.error('There was an error fetching the article:', error));
    },
    splitTags(tagsString) {
      // Assuming your tags are separated by the word 'and' or by a comma followed by a space
      return tagsString.split(/and|, /);
    },
    openPopup(url) {
    const width = 600;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const options = `status=no,height=${height},width=${width},resizable=yes,left=${left},top=${top},screenX=${left},screenY=${top},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`;
    window.open(url, 'SocialShare', options);
  },
} 
  }
  

</script>

<style scoped>
  .article-page {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }


  .image-container {
    margin-bottom: 1em;
    margin-top: 1em;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  /* Optional: If you also want to center vertically in the viewport or within a larger container */
  align-items: center; /* Center vertically */

}

  h1 {
    font-family: 'Playfair Display', sans-serif;
    font-size: 2.5em;
    margin-bottom: 0em;
    margin-top: -0.5em;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }

  .article-metadata {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 0em;
  }

  .article-content {
    
    font-size: 1em;
    line-height: 1.6;
  }


  

  .article-author, .article-date {
    font-size: 0.6em;
    color: #777;
    margin-top: 0px; /* Space above the tags */
    margin-right: 0px; /* Add space between tags */
    
  }

  .article-tags {
    font-size: 0.5em;
    color: #777;
    margin-top: 10px; /* Space above the tags */
    margin-right: 5px; /* Add space between tags */
  }

  .social-share-icons a {
  margin-right: 10px; /* Space between icons */

}

.social-share-icons img {
  width: 14px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  vertical-align: middle; /* Align icons with text if needed */
}
</style>