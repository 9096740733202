import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import ArticlePage from '@/views/ArticlePage.vue'; // Use ArticlePage for articles
import AboutPage from '@/views/AboutPage.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/article/:slug',
    name: 'Article',
    component: ArticlePage,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
  },

  // Additional routes go here
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
