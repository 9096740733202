
<template>
  <div class="content-area">
    <!-- Headlines Section -->
    <section class="section headlines">
      <h2 class="section-title">News</h2>
      
      <ul class="article-list">
  <li v-for="article in headlineArticles" :key="article.id" class="article-item">
    <div class="image-container">
      <img :src="`/img/${article.id}.png`" alt="Article Image"/>
    </div>
    <div class="article-content">
      <div class="article-date">{{ timeAgo(article.publishedDate) }}</div>
      <router-link :to="`/article/${article.slug}`" class="article-title">{{ article.title }}</router-link>
      <div class="article-summary">{{ truncateSummary(article.summary) }}</div>
    </div>
  </li>
</ul>

    </section>

    <!-- Articles Section -->
    <section class="section articles">
      <h2 class="section-title">Headlines</h2>
      <ul class="article-list">
        <li v-for="article in articleList" :key="article.id">
          <div class="article-date">{{ timeAgo(article.publishedDate) }}</div>
          <router-link :to="`/article/${article.slug}`">{{ article.title }}</router-link>

        </li>
      </ul>
    </section>


  </div>
</template>

<script>
export default {
  name: 'MainContent',
  data() {
    return {
      headlineArticles: [],
      articleList: [],
      aiArticles: [],
    };
  },
  mounted() {
    this.fetchArticles();
  },
  methods: {
    fetchArticles() {
      fetch('/data/list.json')
        .then(response => response.json())
        .then(data => {
          // Sort articles by newest date first
          const sortedArticles = data.sort((a, b) => {
            // Convert dates to timestamps for comparison
            const dateA = new Date(a.publishedDate).getTime();
            const dateB = new Date(b.publishedDate).getTime();
            return dateB - dateA; // Sort by newest first
          });

          // Now sortedArticles contains the articles sorted by date
          this.headlineArticles = sortedArticles;
          this.articleList = sortedArticles;
          this.aiArticles = sortedArticles;
        })
        .catch(error => console.error('There was an error fetching the articles:', error));
    },
    truncateSummary(summary) {
      // Your existing truncateSummary function
      if (summary.length <= 100) {
        return summary;
      } else {
        return summary.substring(0, 400) + '...';
      }
    },
    timeAgo(dateString) {
      // Your existing timeAgo function
      const date = new Date(dateString);
      const now = new Date();
      const seconds = Math.floor((now - date) / 1000);
      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years ago";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months ago";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days ago";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours ago";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    },
  },
};
</script>


  
  <style scoped>
  .content-area {
    display: flex;
    flex-direction: column;
  }
  
  .section {
    margin-bottom: 0px;
  }
  
  .section-title {
    font-family: 'Playfair Display', sans-serif;
    font-size: 1em;
    font-weight: bold; 
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 4px solid #333;
  }
  
  .article-list {
    
    list-style-type: none;
    padding: 10;
  }
  
  .article-list li {
    
    margin-bottom: 20px;
    font-weight: bold;
  }

  .article-summary {
    
  color: #757575; /* Grey color */
  font-size: 0.7em; /* Smaller font size */
  margin-top: 5px; /* Space between the link and the summary */
}

.article-date {
  font-size: 0.7em; /* Small font size for date */
  color: #333; /* Darker color for visibility */
  margin-bottom: 0px; /* Space between the date and the title */
}

.article-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.article-item {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align items vertically */
  margin-bottom: 20px; /* Space between items */
}

.image-container {
  flex-shrink: 0; /* Prevent the image from shrinking */
  margin-right: 20px; /* Space between image and text */
}

.image-container img {
  width: 100px; /* Set image width */
  height: 100px; /* Set image height */
  object-fit: cover; /* Make sure the image covers the area */
}

.article-content {
  flex-grow: 1; /* Allow the text content to grow and fill the space */
}

.article-title {
  font-weight: bold; /* Style for the article title */
  color: #333; /* Color for the article title */
  text-decoration: none; /* Remove underline from links */
}

.article-summary {
  color: #666; /* Color for the article summary */
}

.content-area {
  display: flex;
  flex-direction: row-reverse; /* Changed to row to align sections horizontally */
}

.section {
  flex: 1; /* Each section takes up equal space */
  margin-bottom: 20px; /* Add some margin between sections */
  padding: 10px; /* Padding around the content of each section */
}

/* If you want specific control over the section widths, you can add additional classes or IDs */
.headlines {
  flex: 50; /* Adjust the flex value if you want a different size ratio */
}

.articles {
  flex: 20; /* Adjust the flex value if you want a different size ratio */
}

/* Other styles remain the same */


/* Add other styles as needed */



  </style>
  