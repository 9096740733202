import { createApp } from 'vue';
import App from './App.vue'; // The root component
import router from './router'; // Import the router configuration
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


library.add(faFacebookF, faTwitter, faLinkedinIn, faEnvelope);


library.add(faUserSecret);

// If you're using a global CSS file, import it here
import './assets/styles/main.css'; // Import global styles

// Create a new Vue application instance
const app = createApp(App);

// Register Font Awesome Icon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Use the router with the Vue application
app.use(router);

// Mount the Vue application to the DOM
app.mount('#app');
