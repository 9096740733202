<template>
    <div class="clock">
      <h1>{{ currentTime }}</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TimeClock',
    data() {
      return {
        currentTime: this.getCurrentTime(),
      };
    },
    created() {
      this.startClock();
    },
    methods: {
      getCurrentTime() {
        return new Date().toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        });
      },
      startClock() {
        setInterval(() => {
          this.currentTime = this.getCurrentTime();
        }, 500);
      },
    },
  };
  </script>
  
  <style scoped>
  .clock h1 {
    font-size: 1em; /* Adjust the size as needed */
  }
  </style>
  